const menuOpened = new Event('menu-opened');

// Document click listener
document.addEventListener('click', (event) => {
    // Toggle Menu Button
    if (event.target.matches('.js-menu-toggle')) {
        document.body.classList.toggle('menu-open');
        if (document.body.classList.contains('menu-open')) {
            document.dispatchEvent(menuOpened);
        }
        // close search widget and mini cart widget if they are open
        document.body.classList.remove('product-search-active');
        document.body.classList.remove('mini-cart-active');
    }
    // Site search Toggle Button
    else if (event.target.matches('.js-product-search-toggle')) {
        document.body.classList.toggle('product-search-active');
    }
    // Mini cart Toggle and Close Buttons
    else if (event.target.matches('.js-cart-toggle')) {
        //console.log('active');
        document.body.classList.toggle('mini-cart-active');
    }
    else if (event.target.matches('.js-cart-close')) {
        document.body.classList.remove('mini-cart-active');
    }
    // Product Category, Filters Toggle and Close Button. Mobile Only.
    else if (event.target.matches('.js-filters-bar-toggle')) {
        document.body.classList.toggle('filters-bar-active');
    }
});

document.addEventListener('DOMContentLoaded', () => {
    // Dropdown Language Switcher
    const ls = document.querySelector('.js-dropdown-language-switcher');
    if (ls) {
        const lsToggle = ls.querySelector('.js-language-switcher-toggle');
        lsToggle.addEventListener('click', () => {
            ls.classList.toggle('active');
        });
    }
});

document.addEventListener('DOMContentLoaded', () => {
    // Header Sticky Elements
    const headerScrollHandler = () => {
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;
    const offset = windowHeight * 0.22;
    //console.log(offset);

    let ticking = false;
    if (!ticking) {
        window.requestAnimationFrame(() => {
            if (window.scrollY >= offset) {
                document.body.classList.add('sticky-visible');
            } else {
                document.body.classList.remove('sticky-visible');
            }
            ticking = false;
        });
    }
    ticking = true;
    };
    window.addEventListener('scroll', headerScrollHandler, { passive: true });
});

document.addEventListener('DOMContentLoaded', () => {
    const marquee = document.querySelector('.js-marquee');
    let windowWidth = window.innerWidth;

    if (marquee) {
        if (windowWidth > 300){
            marquee.setAttribute('style', '--tw: 128vw; --ad: 3s;');
        }
        if (windowWidth > 480){
            marquee.setAttribute('style', '--tw: 85vw; --ad: 3s;');
        }
        if (windowWidth > 600){
            marquee.setAttribute('style', '--tw: 68vw; --ad: 3s;');
        }
        if (windowWidth > 768){
            marquee.setAttribute('style', '--tw: 54vw; --ad: 3s;');
        }
        if (windowWidth > 1024){
            marquee.setAttribute('style', '--tw: 41vw; --ad: 3s;');
        }
        if (windowWidth > 1300){
            marquee.setAttribute('style', '--tw: 32vw; --ad: 3s;');
        }
        if (windowWidth > 1650){
            marquee.setAttribute('style', '--tw: 25vw; --ad: 3s;');
        }
    }
});
