const submenuToggleBtn = document.querySelectorAll('.menu-item-has-children > .js-toggle-submenu');

if (submenuToggleBtn) {
    for(let i=0; i<submenuToggleBtn.length; i++) {
        submenuToggleBtn[i].addEventListener('click', (event) => {
            event.preventDefault();
            const subMenuActive = document.querySelectorAll('.sub-menu.active');
            const subMenu = submenuToggleBtn[i].parentElement.querySelector('.sub-menu');
            submenuToggleBtn[i].parentElement.classList.toggle("active");
            subMenu.classList.toggle("active");
            submenuToggleBtn[i].classList.toggle("active");
        });
    }
}

