const tabLinks = document.getElementsByClassName("js-tablinks");
const tabContent = document.getElementsByClassName("js-category-list");
if(tabLinks){
    for (let i = 0; i < tabLinks.length; i++) {
        tabLinks[i].addEventListener("click", function(e){        
            if (e.target.classList.contains('active')){
                return false;
            }
            const activeTablink = document.querySelector('.js-tablinks.active');        
            if (activeTablink) {
                const activeTab = document.querySelector('.js-category-list.active');
                activeTablink.classList.remove('active');
                activeTab.classList.remove('active');
            }
            this.classList.add('active');
            tabContent[i].classList.add('active');
            
//            const activeVideo = document.querySelector('.js-category-list.active');
//            const video = document.querySelector('.js-category-list');
//            if (activeVideo) {
//                activeVideo.classList.remove('active');
//
//            }
        }, false );
    }
}