import Drift from 'drift-zoom';
// old code

// const images = document.querySelectorAll('.js-img-zoom');
// const pane = document.querySelectorAll('.single-img-conteiner');
// const screenWidth  = window.screen.width;
//
// if(screenWidth >= 768 && images){
//     for(var i=0; i< images.length; i++) {
//         new Drift(images[i], {
//             paneContainer: pane[i],
//             inlinePane: 100,
//             zoomFactor: 2.5
//         });
//     }
// }

// new code
window.onload = () => {
  // checking if current page is a product page
  if (document.querySelector("body.single-product")){
    // kinda global vars
    let pane, screenWidth;
    let images, timerId;
    let colorPicker, preloadImages, timeId, time;
    // This interval checks the existence of the product-images loaded by our customized woocommerce/single-product/product-image.php file.
    // "Additional Image Variation" plugin overwrites this file a moment later,
    // but our product-image.php file is the only way to predict the preload phase, hence
    // the plugin does not allow customizations and defaults to the WooCommerce behaviour.
    // If product-image.php file is not modified, thumbnails are loaded and then dissapear
    // when the overwrite occurs.
    // Need to be sure that pre-loaded images behave properly.
    // Those "pre-load" images can be fully customized, and so they got a crucial "js-img-zoom" class.
    function initiateImgChecker(){
      //images = document.querySelectorAll('img.attachment-shop_single');
      timerId = setInterval(checkImage, 100);
    }
    initiateImgChecker();

    function checkImage(){
      images = document.querySelectorAll('img.attachment-shop_single');
          clearInterval(timerId);
          zoom();
          imageOverwriteCheck();
    }

    // self explanatory function.
    function zoom(){
      for(var i=0; i< images.length; i++) {
        let url = images[i].src;
        // data attribute used by zoom module. Ugly, but inevitable.
        images[i].setAttribute("data-zoom", url);
      }
      pane = document.querySelectorAll('.woocommerce-product-gallery__image');
      screenWidth  = window.screen.width;
      if(screenWidth >= 768 && images){
          for(var i=0; i< images.length; i++) {
              new Drift(images[i], {
                  paneContainer: pane[i],
                  inlinePane: 100,
                  zoomFactor: 2.5
              });
          }
      }
    }

    // "Additional Image Variation" plugin overwrites our customizations to the
    // product-image.php file, If the plugin is in use. ( see plugin documentation )
    // So, we need to check once more, if the overwrite occurs.
    // Overwrites happens when:
    //  1. <select id="pa_color" ... > exists, AND img elements with "js-img-zoom" exist.
    //     The existence of this element indicates that the plugin is in use.
    //     So, images are the pre-loaded images, and will be for sure be overwritten once, when the plugin loads.
    //     Overwritten images will for sure have the "js-img-zoom" class.
    //     We check with interval until img elements DO NOT have "js-img-zoom" class but do have
    //     "attachment-shop_single" class.
    // Checks below should be run when preloaded images are already handled.
    function imageOverwriteCheck(){
      colorPicker = document.querySelector("#pa_color");
      preloadImages = document.querySelectorAll('img.attachment-shop_single.js-img-zoom');
      // images = document.querySelectorAll('img.attachment-shop_single');
      if(colorPicker && preloadImages){
        timeId = setInterval(checkWhenPreloadIsOverwritten, 50);
      }
    }
    function checkWhenPreloadIsOverwritten(){
      images = document.querySelectorAll('img.attachment-shop_single');
      preloadImages = document.querySelectorAll('img.attachment-shop_single.js-img-zoom');
      if (preloadImages.length===0 && images.length>0) {
        clearInterval(timeId);
        // for(var i=0; i< images.length; i++) {
        //   let url = images[i].src;
        //   // data attribute used by zoom module. Ugly, but inevitable.
        //   images[i].setAttribute("data-zoom", url);
        // }
        zoom();
        listenToColorChange();
      }
    }

    // 2. When <select id="pa_color" ... > exists, AND it's state changes.
    //    The change of state indicates that another color is picked.
    //    At the time which new images are loaded, we need to rerun the zoom code.
    function listenToColorChange(){
      colorPicker = document.querySelector("#pa_color");
      if(colorPicker){
        // listen to pin when select element is used to load different images
        //document.body.addEventListener("change", (e) => {
        //document.body.addEventListener("change", (e) => {
        document.querySelector("form.variations_form").addEventListener("click", (e) => {
          time = setInterval(function(){
            if (!nodeListEquality(images, document.querySelectorAll('img.attachment-shop_single')) && images.length!=0){
              clearInterval(time);
              images = document.querySelectorAll('img.attachment-shop_single');
              zoom();
            }
          },50);
        });
      }
    }
    let nodeListEquality = function (A, B) {
      if (A.length !== B.length){
        return false;
      }
      for (let i = 0; i < A.length; i++) {
        if (A[i] !== B[i]){
          return false;
        }
      }
      return true;
    }


  }
}
