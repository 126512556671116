window.addEventListener('load', () => {
    if (document.body) {
        document.body.classList.add('loaded');
    }
});

const el = document.querySelectorAll('.js-check-vis')
for(let i=0; i < el.length; i++){
    const offsetTop = el[i].offsetTop;
//    el[i].scrollIntoView({ 
//        bottom: offsetTop,
//        behavior: "smooth"
//    });
}