//import helpers functions
import './modules/helpers';
import './modules/script';

//import animation functions
import './modules/animations/animations';
import './modules/animations/inview';
//import './modules/animations/scroll-parallax';

//import map functions
import './modules/map/map';

//import menu functions
import './modules/menu/menu';
import './modules/menu/menu-images';

//import sliders functions
import './modules/sliders/HomepageHeroSlider';
import './modules/sliders/brandsCategoriesSlider';

//import components functions
import './modules/components/language-switcher';
import './modules/components/accordion';
import './modules/components/imgZoom';