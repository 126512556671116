import Swiper from 'swiper';

const brandsSlider = document.querySelector('.js-brands-category-slider-container');

if(brandsSlider) {
    const swiper = new Swiper(brandsSlider, {
        slidesPerView: 1,
        spaceBetween: 0,
        loop: true,
        speed: 800,
        grabCursor: true,
        mousewheelControl: true,
        keyboardControl: true,
        navigation: {
          nextEl: '.js-slider-button-next',
          prevEl: '.js-slider-button-prev',

        },
        breakpoints: {
            600: {
                slidesPerView: 2,
            },
            768: {
                slidesPerView: 3,
            },
            1024: {
                slidesPerView: 4,
            },
        },
    });
}